<template>
  <div
    :class="['zcard', { 'zcard-borderless': noBorder }]"
    @click="click"
  >
    <template v-if="noBody">
      <slot />
    </template>
    <ZCardBody
      v-else
      :title="title"
    >
      <slot />
    </ZCardBody>
  </div>
</template>

<script setup>
defineProps({
  noBody: {
    type: Boolean,
    default: false,
  },

  title: {
    type: String,
    default: undefined,
  },

  noBorder: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click'])
const click = () => emit('click')
</script>
